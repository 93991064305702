

// TODO: Update exec data fetching to be done through an API call, direct reading like this is for frontend dev purpose only!
import execAllData from '../../../data/exec.json'

/**
 * Return an Exec component for display
 * @param execName - Name of the exec
 * 
 * @returns 
 */
function Exec({execName}) {

    const exec = execAllData[execName];

    // process newline characters and add html
    const processNewline = (str) => {

        const lines = str.split(/(\r\n|\n|\r)/gm)
        return (
            <>
                {lines.map((line, index) => {
                    return (
                        <span className='block' key={index}>
                            {line}
                        </span>
                    )
                })}
            </>
        );
    }

    // TODO: Also display execs other socials (Insta, MAL, etc) because I was lazy cooking this up
    return (
        <div className="lg:grid lg:grid-cols-3 lg:my-12">
            <div className="">
                <div className="flex items-center flex-col p-8 lg:gap-y-10 lg:pr-10">
                    <img loading="lazy" src={exec['img']} />
                </div>
            </div>
            <div className="lg:col-span-2">
                <div className="flex flex-col lg:flex-row lg:items-end pb-4 lg:pb-10 lg:gap-x-5">
                    <div>
                        <h2 className="text-[15vw] md:text-8xl leading-none"> {execName.toUpperCase()} </h2>
                    </div>
                    <div>
                        <h3 className="text-ubcani-pink">{processNewline(exec["title"])}</h3>
                        <p className="text-cyan-200 font-bold">{exec["major"]}</p>
                    </div>
                </div>
                <div>
                    <b className="text-lg md:text-3xl text-ubcani-yellow">fav weeb stuff: </b>

                    <p>{processNewline(exec["fav_weeb_stuff"])}</p>
                    
                    <br></br>
                    <b className="text-lg md:text-3xl text-ubcani-yellow">other hobbies: </b>
                    
                    <p>{processNewline(exec["other_hobbies"])}</p>
                    
                    <br></br>

                    <p><i>{processNewline(exec["quote"])}</i></p>
                    
                    <br></br>
                    {exec["pronoun"] !== "" ? 
                    <span className="text-lg md:text-2xl text-ubcani-yellow">{exec["pronoun"]} <span className='text-white'>|</span></span> :
                    <span></span>
                    }        
                    <span className="text-lg md:text-2xl text-cyan-200"> Discord: {exec["discord"]}</span><br></br><br></br>
                </div>
            </div>
            <div className="bg-white text-transparent h-[1px] mb-5 lg:hidden">
                ______
            </div>
        </div>
    );
}

export default Exec;