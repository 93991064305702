
import hollowStar from "../../../../assets/typography/hollowStar.webp"
// import other event previews here
import daigakusai2019 from "../../../../assets/events/gallery/2019/daigakusai.webp"
import halloween2019 from "../../../../assets/events/gallery/2019/halloween.webp"
import icebreaker2019 from "../../../../assets/events/gallery/2019/icebreaker.webp"
import movie2019 from "../../../../assets/events/gallery/2019/movie.webp"
import valentines2019 from "../../../../assets/events/gallery/2019/valentines.webp"

import "../EventGallery.css"

/**
 * Template to build an event gallery component for a specific school year.
 * 
 * Copy this file into its own folder and rename the file to be EventGallery[Year], where Year represent the school year Year/Year+1
 * Example: EventGallery2019 is the event gallery for the school year 2019/2020
 *  
 * This file contains an example of an event gallery component, adjust and add new gallery elements as needed
 * 
 */

function EventGallery2019() {

    // contains the link to the corresponding photo albums
    const galleryLink = {
        "icebreaker2019": "https://photos.app.goo.gl/YSZnzQEp1QyucjYh6",
        "movie2019": "https://photos.app.goo.gl/J6pFbYAWxwVUmAhUA",
        "halloween2019": "https://photos.app.goo.gl/BYbxeur6BLbTcacv5",
        "valentines2019": "https://photos.app.goo.gl/jTeTRWE1z3Px2yee6",
        "daigakusai2019": "https://photos.app.goo.gl/EHbvbCcdMgD27Caf6"
    }

    /**
     * Render the year heading for the current element
     * 
     * @param year the school year, as defined above 
     * @returns the rendered display of the school year
     */
    const schoolYearElement = (year) => {

        return (

            <div className="schoolYearElement">
                <img loading="lazy" src={hollowStar}></img>
                <h2 >
                    {year}/{year + 1}
                </h2>
            </div>
        )
    }

    /**
     * 
     * @param eventName name of the event
     * @param eventGalleryLink link to a gallery of event photos. Usually a Google Photo link
     * @param previewImage preview image, must be imported beforehand
     * @returns the rendered event gallery element
     */

    const eventGalleryElement = (eventName, eventGalleryLink, previewImage) => {

        return (
            <div className="eventGalleryElement">
                <div className="eventGalleryChild1">
                    <a href={eventGalleryLink}>
                        <img loading="lazy" src={previewImage} />
                    </a>
                </div>

                <div>
                    <h3 >
                        {eventName.toUpperCase()}
                    </h3>
                </div>
            </div>
        )
    }

    return (
        <div className="eventGalleryGrid" >

            {schoolYearElement(2019)}

            {eventGalleryElement("ICEBREAKER", galleryLink["icebreaker2019"], icebreaker2019)}
            {eventGalleryElement("SFU+UBC MOVIE OUTING", galleryLink["movie2019"], movie2019)}
            {eventGalleryElement("HALLOWEEN SOCIAL", galleryLink["halloween2019"], halloween2019)}
            {eventGalleryElement("VALENTINES SOCIAL", galleryLink["valentines2019"], valentines2019)}
            {eventGalleryElement("DAIGAKUSAI", galleryLink["daigakusai2019"], daigakusai2019)}
        </div>
    )
}

// don't forget to edit this one as well
export default EventGallery2019;