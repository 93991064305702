
import hollowStar from "../../../../assets/typography/hollowStar.webp"
// import other event previews here
import beach2024 from "../../../../assets/events/gallery/2024/beach2024.webp"
import icebreaker2024 from "../../../../assets/events/gallery/2024/icebreaker2024.webp"
import origami2024 from "../../../../assets/events/gallery/2024/origami2024.webp"
import gameshow2024 from "../../../../assets/events/gallery/2024/gameshow2024.webp"
import presentation2024 from "../../../../assets/events/gallery/2024/presentation2024.webp"
import halloween2024 from "../../../../assets/events/gallery/2024/halloween2024.webp"
import catoro2024 from "../../../../assets/events/gallery/2024/catoro2024.webp"
import karaoke2024_2 from "../../../../assets/events/gallery/2024/karaoke2024_2.webp"
import videogame2024 from "../../../../assets/events/gallery/2024/videogame2024.webp"
import cosplay2024 from "../../../../assets/events/gallery/2024/cosplay2024.webp"
import murderMystery2024 from "../../../../assets/events/gallery/2024/murderMystery2024.webp"
import nihongo2024 from "../../../../assets/events/gallery/2024/nihongo2024.webp"
import karaoke2024_3 from "../../../../assets/events/gallery/2024/karaoke2024_3.webp"
import boardgame2024 from "../../../../assets/events/gallery/2024/boardgame2024.webp"
import dance2024 from "../../../../assets/events/gallery/2024/dance2024.webp"
import visualNovel2024 from "../../../../assets/events/gallery/2024/visualNovel2024.webp"
import karaoke2024_4 from "../../../../assets/events/gallery/2024/karaoke2024_4.webp"
import valentines2024 from "../../../../assets/events/gallery/2024/valentines2024.webp"
import comicWorkshop2024 from "../../../../assets/events/gallery/2024/comicWorkshop2024.webp"
import "../EventGallery.css"

/**
 * Template to build an event gallery component for a specific school year.
 * 
 * Copy this file into its own folder and rename the file to be EventGallery[Year], where Year represent the school year Year/Year+1
 * Example: EventGallery2019 is the event gallery for the school year 2019/2020
 *  
 * This file contains an example of an event gallery component, adjust and add new gallery elements as needed
 * 
 */

function EventGallery2024() {

    // contains the link to the corresponding photo albums
    const galleryLink = {
        "icebreaker2024": "https://photos.app.goo.gl/wncCgDQ7dgGRZ9Sf6",
        "beach2024": "https://photos.app.goo.gl/H15cZYmfLHXFzwNL9",
        "origami2024": "https://photos.app.goo.gl/zcKiFF3FjDogi3W87",
        "gameshow2024": "https://photos.app.goo.gl/xnvMgaV6Sj11crDt7",
        "presentation2024": "https://photos.app.goo.gl/hzP9r5S8rttmhQpd7",
        "halloween2024": "https://photos.app.goo.gl/noxSuNJtkEUZLxtG8",
        "catoro2024": "https://photos.app.goo.gl/MYngBFGxVBBDbAp29",
        "karaoke2024_2": "https://photos.app.goo.gl/Za6M7nW9NdbPg7Ur6",
        "videogame2024": "https://photos.app.goo.gl/ptqUxKpgg5z9Ma1Q9",
        "cosplay2024": "https://photos.app.goo.gl/pdq9rTNWqwPNQSgn6",
        "murderMystery2024": "https://photos.app.goo.gl/jpRVTYjnYSSnpjed9",
        "nihongo2024": "https://photos.app.goo.gl/dzzmoBsmP7JiBUWc7",
        "karaoke2024_3": "https://photos.app.goo.gl/LJyJzKsoSMLXrZwQ9",
        "boardgame2024": "https://photos.app.goo.gl/iNaRXSWyvtvrcSvm9",
        "dance2024": "https://photos.app.goo.gl/fhRvEzsXj5ME3Adz5",
        "visualNovel2024": "https://photos.app.goo.gl/Qdcw1dEHy6zsTucr5",
        "karaoke2024_4": "https://photos.app.goo.gl/a9AERx6T4djTXB3x6",
        "valentines2024": "https://photos.app.goo.gl/216qumhoj7CUpoeS7",
        "comicWorkshop2024": "https://photos.app.goo.gl/5SxsQoWunAKqXbEP7",
        "hanami2024": "https://photos.app.goo.gl/q6qd3vaaXLaqwXDm9"
    }

    /**
     * Render the year heading for the current element
     * 
     * @param year the school year, as defined above 
     * @returns the rendered display of the school year
     */
    const schoolYearElement = (year) => {

        return (

            <div className="schoolYearElement">
                <img loading="lazy" src={hollowStar}></img>
                <h2 >
                    {year}/{year + 1}
                </h2>
            </div>
        )
    }

    /**
     * 
     * @param eventName name of the event
     * @param eventGalleryLink link to a gallery of event photos. Usually a Google Photo link
     * @param previewImage preview image, must be imported beforehand
     * @returns the rendered event gallery element
     */

    const eventGalleryElement = (eventName, eventGalleryLink, previewImage) => {

        return (
            <div className="eventGalleryElement">
                <div className="eventGalleryChild1">
                    <a href={eventGalleryLink}>
                        <img loading="lazy" src={previewImage} />
                    </a>
                </div>

                <div>
                    <h3 >
                        {eventName.toUpperCase()}
                    </h3>
                </div>
            </div>
        )
    }

    return (
        <div className="eventGalleryGrid" >

        {schoolYearElement(2024)}

        {eventGalleryElement("ICEBREAKER", galleryLink["icebreaker2024"], icebreaker2024)}
        {/* {eventGalleryElement("ORIGAMI", galleryLink["origami2024"], origami2024)}
        {eventGalleryElement("GAME SHOW NIGHT", galleryLink["gameshow2024"], gameshow2024)} */}
        {/* {eventGalleryElement("PRESENTATION NIGHT", galleryLink["presentation2024"], presentation2024)} */}
        {eventGalleryElement("HALLOWEEN SOCIAL", galleryLink["halloween2024"], halloween2024)}
        {/* {eventGalleryElement("CATORO OUTING", galleryLink["catoro2024"], catoro2024)} */}
        {eventGalleryElement("KARAOKE", galleryLink["karaoke2024_2"], karaoke2024_2)}
        {/* {eventGalleryElement("VIDEO GAME NIGHT", galleryLink["videogame2024"], videogame2024)} */}
        {/* {eventGalleryElement("COSPLAY WORKSHOP", galleryLink["cosplay2024"], cosplay2024)} */}
        {eventGalleryElement("MURDER MYSTERY", galleryLink["murderMystery2024"], murderMystery2024)}
        {/* {eventGalleryElement("NIHONGO", galleryLink["nihongo2024"], nihongo2024)}
        {eventGalleryElement("KARAOKE 3", galleryLink["karaoke2024_3"], karaoke2024_3)} */}
        {/* {eventGalleryElement("BOARD GAME NIGHT", galleryLink["boardgame2024"], boardgame2024)} */}
        {eventGalleryElement("DANCE WORKSHOP", galleryLink["dance2024"], dance2024)}
        {eventGalleryElement("VISUAL NOVEL NIGHT", galleryLink["visualNovel2024"], visualNovel2024)}
        {/* {eventGalleryElement("KARAOKE 4", galleryLink["karaoke2024_4"], karaoke2024_4)} */}
        {eventGalleryElement("VALENTINES SOCIAL", galleryLink["valentines2024"], valentines2024)}
        {eventGalleryElement("COMIC WORKSHOP", galleryLink["comicWorkshop2024"], comicWorkshop2024)}
        {eventGalleryElement("BEACH EPISODE", galleryLink["beach2024"], beach2024)}
        {/* {eventGalleryElement("POP-UP HANAMI", galleryLink["hanami2024"], hanami2024)} */}

    
        </div>
    )
}

// don't forget to edit this one as well
export default EventGallery2024