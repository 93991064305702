
import hollowStar from "../../../../assets/typography/hollowStar.webp"
// import other event previews here
import beach2023 from "../../../../assets/events/gallery/2023/beach.webp"
import catoro2023 from "../../../../assets/events/gallery/2023/catoro.webp"
import cosplay2023 from "../../../../assets/events/gallery/2023/cosplay workshop.webp"
import daigakusai2023 from "../../../../assets/events/gallery/2023/daigakusai.webp"
import halloween2023 from "../../../../assets/events/gallery/2023/halloween.webp"
import icebreaker2023 from "../../../../assets/events/gallery/2023/icebreaker.webp"
import kaiji2023 from "../../../../assets/events/gallery/2023/kaiji.webp"
import karaoke2023 from "../../../../assets/events/gallery/2023/karaoke.webp"
import murderMystery2023 from "../../../../assets/events/gallery/2023/murder_mystery.webp"
import videoGame2023 from "../../../../assets/events/gallery/2023/video game night.webp"
import wota2023 from "../../../../assets/events/gallery/2023/wota.webp"
import hanami2023 from "../../../../assets/events/gallery/2023/hanami.webp"
import "../EventGallery.css"

/**
 * Template to build an event gallery component for a specific school year.
 * 
 * Copy this file into its own folder and rename the file to be EventGallery[Year], where Year represent the school year Year/Year+1
 * Example: EventGallery2019 is the event gallery for the school year 2019/2020
 *  
 * This file contains an example of an event gallery component, adjust and add new gallery elements as needed
 * 
 */

function EventGallery2023() {

    // contains the link to the corresponding photo albums
    const galleryLink = {
        "icebreaker2023": "https://photos.app.goo.gl/Buc9U4ifLW9xv4CV8",
        "catoro2023": "https://photos.app.goo.gl/yo6W969yD9VAfFnh6",
        "cosplay2023": "https://photos.app.goo.gl/Cs6fEKnH91Yi3hBj8",
        "daigakusai2023": "https://photos.app.goo.gl/7Jm3KueWuE1yYymi9",
        "halloween2023": "https://photos.app.goo.gl/fXGjkJScAw7JTzqK7",
        "kaiji2023": "https://photos.app.goo.gl/CKujQEQaWSpLkN3m7",
        "karaoke2023": "https://photos.app.goo.gl/WqQgV6TQhfN3kbUD9",
        "murderMystery2023": "https://photos.app.goo.gl/PCmxuF3b7JD49m996",
        "videoGame2023": "https://photos.app.goo.gl/SntFWzm1edrWjeha9",
        "wota2023": "https://photos.app.goo.gl/Y2zL3XVWkAbwAdU48",
        "beach2023": "https://photos.app.goo.gl/zqx93W2Xxr5RfB658",
        "hanami2023": "https://photos.app.goo.gl/PyB9G6rNyG6kR1EY7",
    }

    /**
     * Render the year heading for the current element
     * 
     * @param year the school year, as defined above 
     * @returns the rendered display of the school year
     */
    const schoolYearElement = (year) => {

        return (

            <div className="schoolYearElement">
                <img loading="lazy" src={hollowStar}></img>
                <h2 >
                    {year}/{year + 1}
                </h2>
            </div>
        )
    }

    /**
     * 
     * @param eventName name of the event
     * @param eventGalleryLink link to a gallery of event photos. Usually a Google Photo link
     * @param previewImage preview image, must be imported beforehand
     * @returns the rendered event gallery element
     */

    const eventGalleryElement = (eventName, eventGalleryLink, previewImage) => {

        return (
            <div className="eventGalleryElement">
                <div className="eventGalleryChild1">
                    <a href={eventGalleryLink}>
                        <img loading="lazy" src={previewImage} />
                    </a>
                </div>

                <div>
                    <h3 >
                        {eventName.toUpperCase()}
                    </h3>
                </div>
            </div>
        )
    }

    return (
        <div className="eventGalleryGrid" >

        {schoolYearElement(2023)}

        {eventGalleryElement("ICEBREAKER", galleryLink["icebreaker2023"], icebreaker2023)}
        {/* {eventGalleryElement("CATORO OUTING", galleryLink["catoro2023"], catoro2023)} */}
        {/* {eventGalleryElement("COSPLAY WORKSHOP", galleryLink["cosplay2023"], cosplay2023)} */}
        {eventGalleryElement("KARAOKE", galleryLink["karaoke2023"], karaoke2023)}
        {/* {eventGalleryElement("VIDEO GAME NIGHT", galleryLink["videoGame2023"], videoGame2023)} */}
        {eventGalleryElement("HALLOWEEN SOCIAL", galleryLink["halloween2023"], halloween2023)}
        {/* {eventGalleryElement("WOTA + DANCE", galleryLink["wota2023"], wota2023)} */}
        {eventGalleryElement("MURDER MYSTERY", galleryLink["murderMystery2023"], murderMystery2023)}
        {eventGalleryElement("KAIJI NIGHT", galleryLink["kaiji2023"], kaiji2023)}
        {eventGalleryElement("DAIGAKUSAI", galleryLink["daigakusai2023"], daigakusai2023)}   
        {eventGalleryElement("BEACH EPISODE", galleryLink["beach2023"], beach2023)}
        {eventGalleryElement("POP-UP HANAMI", galleryLink["hanami2023"], hanami2023)}

        </div>
    )
}

// don't forget to edit this one as well
export default EventGallery2023