

function SponsorIcon({ id, iconLink, type }) {

    const borderColor = {
        "FOOD": "border-ubcani-pink",
        "MERCH": "border-ubcani-aqua",
        "MISC": "border-ubcani-yellow"
    }

    return (
        <div className={`size-32 lg:size-40 border-solid border-8 rounded-full hover:border-ubcani-light-grey overflow-hidden ${borderColor[type]}`}>
            <a href={"#" + id}>
                <img loading="lazy" className="object-fill object-center" src={iconLink} alt={"sponsor" + id} />
            </a>
        </div>

    )
}

export default SponsorIcon