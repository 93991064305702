import shiani from "../../../assets/shiani.webp";
import tano from "../../../assets/tano.webp";
import mei from "../../../assets/mei.webp";
import shianiK from "../../../assets/shiani kanji.webp";
import tanoK from "../../../assets/tano kanji.webp";
import meiK from "../../../assets/mei kanji.webp";

function Mascot() {

    return (
        <div className="flex justify-center items-center p-5 lg:p-12 lg:w-auto">
            <div className="lg:grid lg:grid-cols-3 lg:justify-left lg:gap-y-40">
                <div className="">
                    <div className="flex items-center flex-col gap-y-10 lg:pr-10">
                        <img loading="" src={shiani} alt="Shiani" />
                    </div>
                </div>
                <div className="col-span-2">
                    <div className="relative">
                        <img loading="" src={shianiK} alt="Shiani Kanji" className="lg:absolute lg:left-52 lg:top-0" />
                        <h2 className="text-[15vw] md:text-9xl relative"> YUBI SHIANI </h2>
                    </div>
                    <p>
                        <b>The mascot of UBC Anime Club.</b><br></br><br></br>
                        She hopes that one day, using her minimum wage pay from
                        McDonald's, she will become a famous streamer.<br></br><br></br>
                        <b>Age:</b> 18<br></br>
                        <b>Birthday:</b> May 26 <br></br>
                        <b>Height:</b> 162 cm (5'4'')<br></br><br></br>
                        <i>Shiani's first design was illustrated in 2021 by Vice-President Kathy
                            Chen, and named and profiled by President Gloria Chow.</i>
                    </p>
                </div>

                <div className="">
                    <div className="flex items-center flex-col gap-y-10 lg:pr-10">
                        <img loading="" src={tano} alt="Tano" />
                    </div>
                </div>

                <div className="col-span-2">
                    <div className="relative">
                        <img loading="" src={tanoK} alt="Tano Kanji" className="lg:absolute lg:left-72 lg:top-0 lg:object-contain" />
                        <h2 className="text-[15vw] md:text-9xl relative"> USHIO TANO </h2>
                    </div>
                    <p>
                        <b>The other mascot of UBC Anime Club.</b><br></br><br></br>
                        A socially inept computer science student who's doing his best, but
                        we're not sure we want him to.<br></br><br></br>
                        <b>Age:</b> 20<br></br>
                        <b>Birthday:</b> June 12 <br></br>
                        <b>Height:</b> 175 cm (5'9'')<br></br><br></br>
                        <i>Tano was designed and drawn by the 2014/15's Creative Director Wan
                            Naruekatpichai and profile by 2014/15's Execs. Ushio is the name
                            selected from our club's naming contest, submitted by Ohne Nene.</i><br></br><br></br>
                    </p>
                    <div className="flex justify-center lg:justify-start lg:flex-row pb-8 lg:pb-0">
                        <a href="about/mascotGallery">
                            <button className="graphics-button">
                                see past graphics of shiani & tano here!
                            </button>
                        </a>
                    </div>


                </div>
                <div className="">
                    <div className="flex items-center flex-col gap-y-10 lg:pr-10">
                        <img loading="" src={mei} alt="Mei" />
                    </div>
                </div>
                <div className="col-span-2">
                    <div className="relative">
                        <img loading="" src={meiK} alt="Mei Kanji" className="lg:absolute lg:left-28 lg:top-0 lg:object-contain" />
                        <h2 className="text-[15vw] md:text-9xl relative"> MEI </h2>
                    </div>
                    <p>
                        <b>A cursed entity that haunts UBC Anime Club.</b><br></br><br></br>
                        The bow is a limiter that suppresses Mei's full power.<br></br><br></br>
                        <b>Age:</b>[̷͖̩̝̩̖͍̇̽̑̓̍Ȑ̴̛̗̼̥̜̩̼̗͔̺̘͈̮̓̉̏̔͊̅͘ͅȄ̸̛͍̌̀̃̂̈̓̿̏͑̉D̶͕̱̈́̈́̈́̊̉̉͐̎̀͒̈́̈́͝A̶̳̮͈̩̤͉͙̗̟͆͆͛̏͒͂͂̆̑͂̕͜͠C̴͎̘̮̮̠͎͖͎͍͉̑̈́T̵̡̳̘̻͚̙͋͌́͐̆̓̽Ę̵̢̙̼̘̥̺̝͓͔̞͌̑̐̋̄͜D̷̨̦̩̹̦̼̯́̅̉̑̂̀̉̚]̶̞̖͒̈́́́̈́̋͑̏͝͝<br></br>
                        <b>Birthday:</b> May <br></br>
                        <b>Height:</b> 1.5 durians tall<br></br><br></br>
                        <i>Mei was redesigned by 2024/25's Screenings Director Loach, based on a plushed created by
                            2018/19's Treasurer Hannah Choi. However, Mei's true sinister origins predate even the
                            plushie, and are unknown to this day.
                        </i>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Mascot;