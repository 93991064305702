import React, { } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Events.css";

import EventDate from "../../components/ui/EventDate"
import EventGallery2019 from "../../components/ui/EventGalleryYear/2019-20/EventGallery2019";
import EventGallery2021 from "../../components/ui/EventGalleryYear/2021-22/EventGallery2021";
import EventGallery2022 from "../../components/ui/EventGalleryYear/2022-23/EventGallery2022";
import EventGallery2023 from "../../components/ui/EventGalleryYear/2023-24/EventGallery2023";
import EventGallery2024 from "../../components/ui/EventGalleryYear/2024-25/EventGallery2024";

import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";

import hollowStar from "../../assets/typography/hollowStar.webp"
import solidStar from "../../assets/typography/solidStar.webp"

import artistMeetups from "../../assets/events/artist_meetups.webp"
import coscafe from "../../assets/events/coscafe.webp"
import screenings from "../../assets/events/screenings.webp"
import socials from "../../assets/events/socials.webp"
import sponsorship from "../../assets/events/sponsorship.webp"
import wota from "../../assets/events/wota.webp"

import hanamiPoster2024 from "../../assets/events/hanami/hanami2024.webp"
import hanamiPoster2025 from "../../assets/events/hanami/hanami2025.webp"

import eventSchedule from "../../data/events2024T2.json"

const DISCORD_LINK = `https://ubcani.com/discord`;
const INSTAGRAM_LINK = `https://www.instagram.com/ubcani`;


function Event() {

  return (
    <div className="App bg-background-blue">
      <header className="App-header">
        <Navbar />
      </header>
      <div id="upcoming" className="bg-background-blue">
        <div className="flex lg:justify-center items-center pl-4 lg:w-42 lg:p-12">
          <div className="flex flex-col">
            <div>
              <h1 className="text-[10vw] md:text-7xl pb-2">UPCOMING
              </h1>
            </div>
            <div className="bg-ubcani-aqua text-transparent h-2 md:h-3">
              ______
            </div>
          </div>
          <img loading="lazy" src={solidStar}></img>


        </div>
        <div className="lg:pl-15 lg:pr-15 xl:pl-20 xl:pr-20 pl-4 pr-2">
          <p className="lg:pb-12 pb-4 text-pretty">
            We host drop-in activities every Friday from 5pm to 10pm, which include karaoke, anime-themed trivia, video games, workshops, and much more! These weeklies are drop-in and free to attend.
          </p>
          <p className="lg:pb-12 pb-4 text-pretty">
            We also hold larger, seasonal events (such as holiday-themed socials, contests, and our annual Pop-Up Hanami), as well as outings and collaborations with other anime clubs.
          </p>
          <p className="lg:pb-20 pb-4 text-pretty">
            Below is our overview of upcoming events for this coming term. Keep an eye out on our <a href={INSTAGRAM_LINK} className="text-ubcani-aqua hover:underline">Instagram</a> or <a href={DISCORD_LINK} className="text-ubcani-aqua hover:underline">Discord</a> for more details!
          </p>
          <ul className="lg:columns-2 lg:px-32 xl:px-40 lg:pb-20">
            {eventSchedule.map((event) => {
              return (
                <li key={event["name"]}>
                  <EventDate month={event["month"]} day={event["day"]} eventName={event["name"]} isBigEvent={event["is_big"]} />
                </li>
              )
            })}
          </ul>
        </div>

        <div className="flex lg:justify-center items-center pl-4 lg:pl-0 pb-2 lg:pb-12 lg:w-42 lg:p-12">
          <img loading="lazy" className="hidden lg:block" src={hollowStar}></img>
          <div className="flex flex-col">
            <div>
              <h1 className="text-[9vw] md:text-7xl pb-2">WEEKLIES<br></br>& SOCIALS</h1>
            </div>
            <div className="bg-ubcani-aqua text-transparent h-2 md:h-3">
              ______
            </div>
          </div>
          <img loading="lazy" src={solidStar}></img>
        </div>
        <div className="lg:pl-15 lg:pr-15 xl:pl-20 xl:pr-20 pl-4 pr-2">
          <p className="lg:pb-12 pb-4 text-pretty">
            We host drop-in activities every Friday from 5pm to 10pm, which include karaoke, anime-themed trivia, video games, workshops, and much more! These weeklies are drop-in and free to attend.               </p>
          <p className="lg:pb-12 pb-4 text-pretty">
            We also hold larger, seasonal events (such as holiday-themed socials, contests, and our annual Pop-Up Hanami), as well as outings and collaborations with other anime clubs.               </p>
          <p className="lg:pb-12 pb-4 text-pretty">
            If you're interested in knowing more about our events, feel free to check out our <a href="#gallery" className="text-ubcani-aqua hover:underline">event gallery</a>!              </p>
        </div>
        <div className="lg:grid lg:grid-cols-3 pr-4 lg:pr-15 pl-4 lg:pl-15 xl:pl-20 xl:pr-20 lg:gap-x-10 items-stretch">
          <div className="flex flex-col">
            <div className="border-solid border-8 mx-auto hover:border-ubcani-yellow rounded-3xl overflow-hidden w-full h-[200px]">
              <img loading="lazy" className="object-cover size-full" src={screenings} />
            </div>

            <div>
              <h3 className="text-right pb-10">
                SCREENINGS
              </h3>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="border-solid border-8 mx-auto rounded-3xl hover:border-ubcani-yellow overflow-hidden w-full h-[200px]">
              <img loading="lazy" className="object-cover size-full" src={artistMeetups} />
            </div>

            <div>
              <h3 className="text-right pb-10">
                ARTIST MEETUPS
              </h3>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="border-solid border-8 mx-auto rounded-3xl hover:border-ubcani-yellow overflow-hidden w-full h-[200px]">
              <img loading="lazy" className="object-cover size-full" src={wota} />
            </div>

            <div>
              <h3 className="text-right pb-10">
                WORKSHOPS
              </h3>
            </div>
          </div>


          <div className="flex flex-col">
            <div className="border-solid border-8 mx-auto rounded-3xl hover:border-ubcani-yellow overflow-hidden w-full h-[200px]">
              <img loading="lazy" className="object-cover size-full" src={socials} />
            </div>

            <div>
              <h3 className="text-right pb-10">
                SOCIALS
              </h3>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="border-solid border-8 mx-auto rounded-3xl hover:border-ubcani-yellow overflow-hidden w-full h-[200px]">
              <img loading="lazy" className="object-cover size-full" src={coscafe} />
            </div>

            <div>
              <h3 className="text-right pb-10">
                COSCAFES
              </h3>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="border-solid border-8 mx-auto rounded-3xl hover:border-ubcani-yellow overflow-hidden w-full h-[200px]">
              <img loading="lazy" className="object-cover size-full" src={sponsorship} />
            </div>

            <div>
              <h3 className="text-right pb-10">
                SPONSORSHIP
              </h3>
            </div>
          </div>
        </div>



      </div>
      <div id="hanami" className="bg-background-blue">
        <div className="flex lg:justify-center items-center pl-4 lg:w-42 lg:p-12">
          <div className="flex flex-col">
            <div>
              <h1 className="text-[11.5vw] md:text-7xl pb-2">POP-UP HANAMI</h1>
            </div>
            <div className="bg-ubcani-pink text-transparent h-3">
              ______
            </div>
          </div>
          <img loading="lazy" src={solidStar}></img>
        </div>
        <div className="flex flex-col lg:flex-row gap-x-10 p-4 lg:p-20">
          <div className="flex flex-col flex-row gap-y-5">
            <img loading="lazy" className="p-4 lg:p-0" src={hanamiPoster2024}></img>

          </div>
          <div>
            <img loading="lazy" className="p-4 lg:p-0" src={hanamiPoster2025}></img>

          </div>
          <div className="pl-15">
            <div className="pb-4 lg:pb-10">
              <p className="pb-4 lg:pb-10">
                A one-day, cherry-blossom-themed event at the University of British Columbia, featuring a cosplay café, an artist alley, stage performances, games & raffles, and more!
              </p>
              <p>
                Held at the AMS Nest student union building, which sees 2,000+ students and visitors everyday. Our main exhibition venue is The Great Hall, a beautiful 8882 ft² ballroom with floor to ceiling windows.
              </p>
            </div>

            <div className="flex justify-center lg:flex-row pb-12 lg:pb-20 p-4">
              <a href="https://www.popup-hanami.ca/">
                <button className="graphics-button">
                  read more here!
                </button>
              </a>
            </div>

          </div>
        </div>
      </div>
      <div id="gallery" className="bg-background-blue px-4">
        <div className="flex justify-center items-center lg:w-42 lg:p-12">
          <div className="flex flex-col">
            <div>
              <h1 className="text-[11.5vw] md:text-7xl pb-2">GALLERY</h1>
            </div>
            <div className="bg-ubcani-yellow text-transparent h-3">
              ______
            </div>
          </div>
          <img loading="lazy" src={solidStar}></img>
        </div>

        <EventGallery2024 />

        <EventGallery2023 />

        <EventGallery2022 />

        <EventGallery2021 />

        <EventGallery2019 />

      </div>
      <Footer />
    </div>
  );
}

export default Event;
