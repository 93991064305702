import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/Horizontal Small White.png";
import "./Navbar.css";

function Navbar() {

  const [isOpen, setIsOpen] = useState(false);
  const [isAboutOpen, setAboutOpen] = useState(false);
  const [isEventsOpen, setEventsOpen] = useState(false);

  const openNavBar = () => {
    setAboutOpen(false)
    setEventsOpen(false)
    setIsOpen(true);
  }

  const closeNavBar = () => {
    setIsOpen(false)
  }

  let location = useLocation()

  const colorTabName = ((path) => {
    const currentPath = location.pathname
    const regExp = new RegExp(path + ".*")

    // check to see if the current path is the passed in path with 0 or more additional characters
    if (currentPath.match(regExp)) {
      switch (path) {
        case "/about":
          {
            return "text-ubcani-yellow"
          }
        case "/events": {
          return "text-ubcani-light-blue"
        }
        case "/membership": {
          return "text-ubcani-pink"
        }
        case "/sponsors": {
          return "text-ubcani-yellow"
        }
        case "/join": {
          return "bg-ubcani-light-blue border-ubcani-light-blue"
        }
        default: {
          // so eslint don't complain
          return ""
        }
      }
    } else {
      return ""
    }
  })

  return (
    <div className="Navbar lg:flex justify-between items-center lg:px-12 pb-4 lg:py-10">
      <div className="py-8 px-4 lg:p-4 flex justify-between">
        <div className="Logo-container">
          <Link to="/">
            <img loading="lazy" src={logo} className="w-[40vw] lg:w-[250px]" alt="logo" />
          </Link>
        </div>
        <button className="responsive-dropdown-btn lg:hidden" type="button" onClick={() => {isOpen ? closeNavBar() : openNavBar()}}>
          <span className="sr-only">Open main menu</span>
          <svg className="w-[5vw] h-[5vw]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
          </svg>
        </button>
      </div>

      <nav id="navbar-large-screen" className={` Dropdown-container h-72`}>
        <ul className="flex flex-row justify-center items-center ">
          <li>
            <div className="dropdown flex">
              <Link to="/about" className={`Navbar-link about-btn text-2xl ${colorTabName("/about")}`}>About</Link>
              <ul className="dropdown-content text-2xl" style={{ backgroundColor: "#FDCC1C" }}>
                <li><a href="/about#mascots">Mascots</a></li>
                <li><a href="/about#execs">Execs</a></li>
                <li><a href="/about#artists">Artists</a></li>
              </ul>
            </div>
          </li>

          <li>
            <div className="dropdown flex">
              <Link to="/events" className={`Navbar-link events-btn text-2xl ${colorTabName("/events")}`}>Events</Link>
              <ul className="dropdown-content text-2xl" style={{ backgroundColor: "#0AFFE0" }}>
                <li><a href="/events#upcoming">Upcoming</a></li>
                <li><a href="/events#hanami">Pop-Up Hanami</a></li>
                <li><a href="/events#gallery">Gallery</a></li>
              </ul>
            </div>
          </li>
          <li>
            <Link to="/membership" className={`Navbar-link membership-btn text-2xl ${colorTabName("/membership")}`}>Membership</Link>
          </li>
          <li>
            <Link to="/sponsors" className={`Navbar-link sponsor-btn text-2xl ${colorTabName("/sponsors")}`}>Sponsors</Link>
          </li>
          <li className="pl-10">
            <Link to="/join" className={`join-us-btn text-2xl ${colorTabName("/join") !== "" ? colorTabName("/join") : "bg-[#e3eaff]"}`}>Join us!</Link>
          </li>
        </ul>
      </nav>

      {/* I tried making this responsive using only 1 navbar but it resulted in a massive 
        headache that it's just easier to make two, one for small screen and other for large. Name reflected for clarity  
        Good luck refactoring it btw I hope god have mercy on your soul.*/}
      <div className={`lg:hidden overflow-hidden transition-all duration-300 ease-in-out transform ${isOpen ? "translate-y-0 max-h-screen opacity-100" : "translate-y-full max-h-0 opacity-0"}`}>
        <nav id="navbar-small-screen" className={`Dropdown-container`}>
          <ul id="list-mobile">
            <li className="">
              <div className="flex flex-col ">
                <div className="flex">
                  <Link to="/about" className={`Navbar-link text-left about-btn text-[4vw] grow ${colorTabName("/about")} active:bg-[#13177c]`}>About</Link>
                  {isAboutOpen ?
                    <div className={`text-[5vw] w-10 flex justify-center items-center`} onClick={() => setAboutOpen(false)}>-</div> :
                    <div className={`text-[5vw] w-10 flex justify-center items-center`} onClick={() => setAboutOpen(true)}>+</div>
                  }
                </div>

                <ul className={`${isAboutOpen ? `block` : `hidden`}`}>
                  <li className="dropdown-li-mobile active:bg-[#13177c]"><a href="/about#mascots">Mascots</a></li>
                  <li className="dropdown-li-mobile active:bg-[#13177c]"><a href="/about#execs">Execs</a></li>
                  <li className="dropdown-li-mobile active:bg-[#13177c]"><a href="/about#artists">Artists</a></li>
                </ul>
              </div>
            </li>

            <li className="">
              <div className="flex flex-col">
                <div className="flex">
                  <Link to="/events" className={`Navbar-link text-left  events-btn text-[4vw] grow ${colorTabName("/events")} active:bg-[#13177c]`}>Events</Link>
                  {isEventsOpen ?
                    <div className={`text-[5vw] w-10 flex justify-center items-center`} onClick={() => setEventsOpen(false)}>-</div> :
                    <div className={`text-[5vw] w-10 flex justify-center items-center`} onClick={() => setEventsOpen(true)}>+</div>
                  }
                </div>

                <ul className={`${isEventsOpen ? `block` : `hidden`}`}>
                  <li className="dropdown-li-mobile active:bg-[#13177c]"><a href="/events#upcoming">Upcoming</a></li>
                  <li className="dropdown-li-mobile active:bg-[#13177c]"><a href="/events#hanami">Pop-Up Hanami</a></li>
                  <li className="dropdown-li-mobile active:bg-[#13177c]"><a href="/events#gallery">Gallery</a></li>
                </ul>
              </div>
            </li>
            <li className="active:bg-[#13177c]">
              <div className="flex">
                <Link to="/membership" className={`Navbar-link text-left membership-btn text-[4vw] grow ${colorTabName("/membership")}`}>Membership</Link>
              </div>
            </li>
            <li className="active:bg-[#13177c]">
              <div className="flex">
                <Link to="/sponsors" className={`Navbar-link text-left sponsor-btn text-[4vw] grow ${colorTabName("/sponsors")}`}>Sponsors</Link>
              </div>
            </li>
            <li className="active:bg-[#13177c]">
              <div className="flex">
                <Link to="/join" className={`text-left  join-us-btn text-[4vw] ${colorTabName("/join") !== "" ? colorTabName("/join") : "bg-[#e3eaff]"}`}>Join us!</Link>
              </div>
            </li>
          </ul>
        </nav>
      </div>


    </div>
  );
}
export default Navbar;
