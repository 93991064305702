import React, {  } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Membership.css";

import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";

import questions from "../../assets/membership/questions.png";
import member from "../../assets/membership/member.png";
import sponsors from "../../assets/membership/sponsors.png";
import prizes from "../../assets/membership/prizes.png";
import membership_card from "../../assets/membership/membership_card.png";

function Membership() {

  return (
    <div className="App">
      <header className="App-header">
        <Navbar />
      </header>
      <div className="flex flex-col px-4 md:px-8 lg:px-24 xl:px-48 lg:py-20 text-text-white bg-background-blue">
        <div>
          <h2 className="text-[15vw] lg:text-9xl pb-4 lg:pb-10">
            WELCOME TO UBCANI!
          </h2>
        </div>
        <div>
          <p className="text-pretty">
            The UBC Anime Club is a student-run organization that brings together individuals who share a passion for anime, manga, and Japanese culture. We have members from all across the lower mainland, and we host big and small events all throughout the year.
          </p>
        </div>

        <div className="pt-4 lg:pt-20">
          <div className="relative pt-10">
            <img loading="lazy" src={member} className="absolute left-0 top-5 object-contain"></img>
            <h3 className="relative text-[10vw] md:text-6xl pb-10">MEMBERSHIP</h3>
          </div>
          <div>
            <p>
              You can buy an annual membership card at any of our events and at our clubroom (as long as an exec is present!). Member benefits include:
            </p>
          </div>
        </div>

        <div className="pt-4 lg:pt-20">
          <div className="flex flex-col xl:flex-row gap-x-10">
            <div id="step1">
              <div className="flex flex-row items-center">
                <div>
                  <h1 className="text-[15vw] lg:text-8xl text-ubcani-aqua">01</h1>
                </div>
                <div className="pl-8">
                  <p className="font-bold text-pretty">Exclusive member discounts at our sponsor locations</p>
                </div>
              </div>
              <div className="flex flex-col items-center p-4">
                <img loading="lazy" src={sponsors} className="pb-4"></img>
                <a href="./sponsors">
                  <button className="graphics-button">
                    see more of our sponsors here!
                  </button>
                </a>
              </div>
            </div>
            <div className="bg-white w-[2px]"></div>
            <div id="step2">
              <div className="flex flex-row items-center">
                <div>
                  <h1 className="text-[15vw] lg:text-8xl text-ubcani-aqua">02</h1>
                </div>
                <div className="pl-8">
                  <p className="font-bold text-pretty">Discounted entry and bonus raffle tickets at our events</p>
                </div>
              </div>
              <div className="flex flex-row p-4 justify-center">
                <img loading="lazy" src={prizes} className="pb-4"></img>
              </div>
            </div>
          </div>
          <div id="step3">
            <div className="flex flex-col xl:flex-row gap-x-20 items-center">
              <div className="flex flex-col xl:w-2/3 gap-y-4 lg:gap-y-8">
                <div className="">
                  <div className="flex flex-row items-center xl:pt-20">
                    <div>
                      <h1 className="text-[15vw] lg:text-8xl text-ubcani-aqua">03</h1>
                    </div>
                    <div className="pl-8">
                      <p className="font-bold">A unique membership card <br></br> design to keep every year!</p>
                    </div>
                  </div>
                </div>
                <div>
                  <p>
                    Purchasing a (very cute) membership card helps support the club so we can continue running events. And remember, you do NOT have to be a member to participate in any club activities!
                  </p>
                </div>
              </div>
              <div className="pt-4 lg:pt-20">
                <img loading="lazy" src={membership_card}></img>
              </div>
            </div>


          </div>
        </div>

        <div id="questions" className="pt-8 lg:pt-20">
          <div className="relative pt-10">
            <img loading="lazy" src={questions} className="absolute left-0 top-5 object-contain"></img>
            <h3 className="relative text-[10vw] md:text-6xl pb-10">QUESTIONS?</h3>
          </div>
          <div>
            <div className="pb-8">
              <p className="font-bold text-ubcani-aqua">
                Q: Do I need to be a UBC student or university student to be a member?
              </p>
              <p className="text-text-white">
                A: Nope! You can be a community member or a UBC student. However, we have a limited number of community memberships available, so be sure to get one early!
              </p>
            </div>

            <div className="pb-8">
              <p className="font-bold text-ubcani-aqua">
                Q: Do I need to have a membership to attend events?             </p>
              <p className="text-text-white">
                A: None of our events are member-limited.             </p>
            </div>

            <div className="pb-8">
              <p className="font-bold text-ubcani-aqua">
                Q: How much are memberships?                </p>
              <p className="text-text-white">
                A: Annual memberships are $10.00 for UBC students, and $15.00 for non-UBC students. However, returning members are eligible for a discount if you present your membership card from previous years.                 </p>
            </div>

            <div className="pb-8">
              <p className="font-bold text-ubcani-aqua">
                Q: Can’t you guys make memberships cheaper?                </p>
              <p className="text-text-white">
                A: Unfortunately, the AMS has rules in place for every club regarding the cost of memberships, but returning members are eligible for a discount!              </p>
            </div>

            <div className="pb-8">
              <p className="font-bold text-ubcani-aqua">
                Q: Where do you guy have your events?               </p>
              <p className="text-text-white">
                A: Information on the location of our events will always be posted on our Discord in the #announcements channel a week before the event, as well as on our Instagram!               </p>
            </div>

            <div className="pb-8">
              <p className="font-bold text-ubcani-aqua">
                Q: Help! I have a question that isn’t answered by this FAQ.                </p>
              <p className="text-text-white">
                A: If you still cannot find the answer to your question, you can reach out to us by emailing us at info@ubcani.com, messaging us at instagram.com/ubcani, or by pinging an exec in our Discord Server!</p>
            </div>

          </div>
        </div>

      </div>




      <Footer />
    </div>
  );
}

export default Membership;
