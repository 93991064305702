import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./About.css";

import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import Mascot from "./Mascot/Mascot";
import Exec from "./Exec/Exec";
import ArtistCollective from "../../components/ui/ArtistCollective";

import solidStar from "../../assets/typography/solidStar.webp";


const execNames = [
  "keltie", 
  "lynn", 
  "xavier", 
  "erin", 
  "amei", 
  "xelena", 
  "agastya", 
  "meraki", 
  "yaseki", 
  "loach"
]

function About() {

  return (
    <div className="App">
      <header className="App-header">
        <Navbar />
      </header>
      <div className="Mascots">
        <div className="flex lg:w-42 items-center pl-4 md:pl-8 lg:p-12 lg:pl-20">
          <div className="flex flex-col">
            <div>
              <h1 className="text-[11.5vw] md:text-7xl pb-2">
                MASCOTS
              </h1>
            </div>
            <div className="bg-ubcani-aqua text-transparent h-2 md:h-3">
              ______
            </div>
          </div>
          <img loading="lazy" className="h-2/5 lg:h-auto" src={solidStar} alt="Hanami Star"></img>
        </div>
        <Mascot />
      </div>

      <div id="execs">
        <div className="flex lg:w-42 items-center pl-4 md:pl-8 lg:p-4 lg:pl-20">
          <div className="flex flex-col">
            <div>
              <h1 className="text-[11.5vw] md:text-7xl pb-2">EXECS
              </h1>
            </div>
            <div className="bg-ubcani-pink text-transparent h-2 md:h-3">
              ______
            </div>
          </div>
          <img loading="lazy" src={solidStar}></img>
        </div>
        <div className="flex justify-center items-center p-5 md:p-8 lg:p-4 lg:w-auto">
          <div>

            {execNames.map((execName) => {
              return <Exec execName={execName} key={execName}></Exec>
            })}

          </div>
        </div>
      </div>
      <div id="artists" className="Artists">
      </div>


      <ArtistCollective />
      <Footer />
    </div>
  );
}

export default About;
