import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./MascotGallery.css";
import Navbar from "../../../layouts/Navbar";
import Footer from "../../../layouts/Footer";

import shiani2021 from "../../../assets/mascotGallery/shiani_2021.webp"
import tano2021 from "../../../assets/mascotGallery/tano_2021.webp"
import conceptArtsShiani from "../../../assets/mascotGallery/concept_art_shiani.webp"
import conceptArtsTano from "../../../assets/mascotGallery/concept_art_tano.webp"
import membership2021 from "../../../assets/mascotGallery/membership2021.webp"
import membership2021Shiani from "../../../assets/mascotGallery/membership2021_shiani.webp"
import membership2021Tano from "../../../assets/mascotGallery/membership2021_tano.webp"
import mascotArtFight from "../../../assets/mascotGallery/mascot_art_fight.webp"
import valentines2021 from "../../../assets/mascotGallery/valentines2021_graphic.webp"
import videogamenight2021 from "../../../assets/mascotGallery/videogamenight2021_graphic.webp"
import maid from "../../../assets/mascotGallery/maid.webp"
import tanoShiani from "../../../assets/mascotGallery/tano_shiani.webp"
import valentines from "../../../assets/mascotGallery/valentines.webp"
import membership from "../../../assets/mascotGallery/membership.webp"
import collabArt from "../../../assets/mascotGallery/iirucrira_art.webp"
import board_game from "../../../assets/mascotGallery/board_game.webp"
import karaoke from "../../../assets/mascotGallery/karaoke.webp"
import membership2020 from "../../../assets/mascotGallery/membership2020.webp"
import puellaMagi from "../../../assets/mascotGallery/puella_magi_ubcani.webp"
import beachOuting from "../../../assets/mascotGallery/beach_outing.webp"

import hollowStar from "../../../assets/typography/hollowStar.webp"
import solidStar from "../../../assets/typography/solidStar.webp"

function MascotGallery() {

  // Fair warning, the UX for this page is ass. I mean genuinely awful.
  return (
    <div className="App">
      <header className="App-header">
        <Navbar />
      </header>
      <div className="lg:px-20 mb-4 lg:mb-20 overflow-hidden">
        <div className="flex justify-center items-center p-4 lg:w-42 lg:p-12">
          <div className="flex flex-row items-center gap-x-2">
            <img loading="lazy" className="h-[15vw] lg:h-20" src={solidStar} alt=""></img>
            <h1 className="text-[7vw] md:text-7xl pb-2">SHIANI & TANO</h1>
            <img loading="lazy" className="h-[15vw] lg:h-20" src={hollowStar} alt=""></img>
          </div>
        </div>

        <div className="lg:hidden text-center">
          <p>!! Recommend viewing this page on a larger screen !!</p>
        </div>

        {/* Shiani and Tano concept arts on tablet/pc */}
        <div className="hidden xl:flex flex-col justify-center">
          <div className="flex justify-center items-end pb-10">
            <img loading="lazy" className="object-contain h-3/5" src={shiani2021} alt="Shiani 2021" />
            <img loading="lazy" className="object-contain h-4/5" src={conceptArtsShiani} alt="concept arts of Shiani" />
          </div>
          <div className="flex justify-center items-end pb-10">
            <img loading="lazy" className="object-contain h-4/5" src={conceptArtsTano} alt="concept arts of Tano" />
            <img loading="lazy" className="object-contain h-3/5" src={tano2021} alt="Tano 2021" />
          </div>
        </div>

        {/* Shiani and Tano concept arts on mobile */}
        <div className="xl:hidden flex flex-col justify-center">
          <div className="flex justify-center">
            <img loading="lazy" className="size-2/5" src={shiani2021} alt="Shiani 2021" />
            <img loading="lazy" className="size-2/5" src={tano2021} alt="Tano 2021" />
          </div>
          <img loading="lazy" className="pb-4" src={conceptArtsShiani} alt="concept arts of Shiani" />
          <img loading="lazy" className="pb-4" src={conceptArtsTano} alt="concept arts of Tano" />
        </div>

        <div className="flex justify-center lg:w-full lg:gap-x-8 pb-4 lg:pb-8 xl:h-[590px]">
          <img loading="lazy" className="w-1/3 lg:w-auto" src={membership2021Shiani} alt="membership 2021 art of Shiani" />
          <img loading="lazy" className="w-1/3 lg:w-auto" src={membership2021} alt="membership 2021 art" />
          <img loading="lazy" className="w-1/3 lg:w-auto" src={membership2021Tano} alt="membership 2021 art of Tano" />
        </div>

        <div className="pb-4 lg:pb-8 flex justify-center" >
          <img loading="lazy" className="" src={mascotArtFight} alt="graphic for 2021 mascot art fight" />
        </div>

        <div className="flex flex-col xl:flex-row xl:justify-center gap-x-8 lg:pb-8 xl:h-[350px]" >
          <img loading="lazy" className="object-contain pb-4" src={valentines2021} alt="graphic for valentines 2021" />
          <img loading="lazy" className="object-contain pb-4" src={videogamenight2021} alt="graphic for vg night 2021" />
        </div>

        <div className="flex flex-wrap justify-center xl:gap-x-8 lg:w-full pb-4 lg:pb-8 h-auto">
          <img loading="lazy" className="object-contain pb-4" src={maid} alt="maid tano and shiani" />
          <img loading="lazy" className="object-contain pb-4" src={tanoShiani} alt="art of tano and shiani" />
          <img loading="lazy" className="object-contain pb-4" src={valentines} alt="art of older valentines event" />
        </div>

        <div className="flex justify-center">
          <img loading="lazy" className="object-contain pb-4" src={membership} alt="old membership art" />
        </div>

        <div className="flex flex-col xl:flex-row justify-center xl:gap-x-8">
          <img loading="lazy" className="object-contain pb-4" src={collabArt} alt="art with other aniclub mascots" />
          <div className="flex flex-col justify-between">
            <img loading="lazy" className="object-contain pb-4" src={board_game} alt="board game night graphic" />
            <img loading="lazy" className="object-contain pb-4" src={karaoke} alt="karaoke night graphic" />
          </div>
        </div>

        <div className="flex justify-center">
          <img loading="lazy" className="object-contain pb-4" src={membership2020} alt="membership 2020 art" />
        </div>

        <div className="flex flex-col xl:flex-row justify-center xl:gap-x-8">
          <img loading="lazy" className="object-contain pb-4" src={puellaMagi} alt="puella magi themed halloween art" />
          <img loading="lazy" className="object-contain pb-4" src={beachOuting} alt="beach outing art" />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default MascotGallery;
