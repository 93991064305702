import React, {  } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Join.css";

import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import hollowStar from "../../assets/typography/hollowStar.webp"
import solidStar from "../../assets/typography/solidStar.webp"

function Join() {

  return (
    <div className="App">
      <header className="App-header">
        <Navbar />
      </header>
      <div id="join" className="bg-background-blue">
        <div className="flex justify-center items-center lg:w-42 lg:p-12">
          <div className="flex flex-row">
            <div className="flex flex-col">
              <h1 className="text-[10vw] lg:text-7xl items-center">JOIN THE</h1>
              <div className="flex items-center justify-center gap-x-5">
                <img loading="lazy" className="h-20" src={hollowStar} alt=""></img>
                <h1 className="text-[10vw] lg:text-7xl pb-2 text-center">CLUB!</h1>
              </div>
            </div>
            <img loading="lazy" className="h-20 lg:h-auto" src={solidStar}></img>
          </div>
        </div>

        <div>
          <div className="flex flex-col lg:flex-row lg:px-40 lg:pb-20 lg:gap-x-10 p-4">
            <div>
              <div className="numbering-icon hidden lg:flex">
                <h1 className="text-8xl text-center ">01</h1>
              </div>
            </div>
            <div className="lg:w-2/3">
              <div className="flex lg:block justify-start items-center p-4 lg:p-0 gap-x-8">
                <div className="numbering-icon flex grow-0 shrink-0 lg:hidden">
                  <h1 className="text-[8vw] text-center ">01</h1>
                </div>
                <h2 className="text-5xl md:text-6xl lg:text-7xl text-ubcani-pink">PURCHASE A MEMBERSHIP</h2>
              </div>
              <p>
                Come talk to an executive at an event or message one of us on discord to purchase a
                membership! Annual memberships are $10.00 for UBC students and $15.00 for non-UBC
                students. However, returning members are eligible for a discount if you present
                your membership card from previous years.
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:px-40 lg:pb-20 lg:gap-x-10 p-4">
            <div className="numbering-icon hidden lg:flex">
              <h1 className="text-8xl text-center ">02</h1>
            </div>
            <div className="lg:w-2/3">
              <div className="flex lg:block justify-start items-center p-4 lg:p-0 gap-x-8">
                <div className="numbering-icon flex grow-0 shrink-0 lg:hidden">
                  <h1 className="text-[8vw] text-center ">02</h1>
                </div>
                <h2 className="text-5xl md:text-6xl lg:text-7xl text-ubcani-yellow">PICK UP YOUR UBCANI CARD</h2>
              </div>
              <p>
                Come to one of our events or message an exec to organize a time,
                and we’ll give you your super cool new membership card and finalize
                the details of your purchase!
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:px-40 pb-12 lg:pb-20 lg:gap-x-10 p-4">
            <div className="numbering-icon hidden lg:flex">
              <h1 className="text-8xl text-center ">03</h1>
            </div>
            <div className="lg:w-2/3">
              <div className="flex lg:block justify-start items-center p-4 lg:p-0 gap-x-8">
                <div className="numbering-icon flex grow-0 shrink-0 lg:hidden">
                  <h1 className="text-[8vw] text-center ">03</h1>
                </div>
                <h2 className="text-5xl md:text-6xl lg:text-7xl  text-ubcani-aqua">ENJOY YOUR BENEFITS!</h2>
              </div>
              <div className="flex flex-col gap-y-5">
                <p>
                  Your membership comes with a lot of amazing benefits like discounts with our
                  sponsors, discounts on tickets for events, and more! Reach out to an exec for
                  further questions or visit our <a href="/membership#questions" className="text-ubcani-light-blue">FAQ page</a>.
                </p>
                <p>
                  We thank you for your continued support of the club! Each membership sale goes
                  directly back into our events and allows us to create a better experience for our
                  attendees!
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center lg:flex-row lg:px-40 pb-12 lg:pb-20 lg:gap-x-10 p-4">
            <a href="https://tinyurl.com/2w6ykf2d">
              <button className="graphics-button">
                buy membership here!
              </button>
            </a>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Join;
