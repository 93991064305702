

/**
 * Render a event date play for the given date in MM/DD
 *
 */
function EventDate({month = 0, day = 0, eventName = "", isBigEvent = false}) {

    const renderDate = ((month, day) => {
        let monthString = ""
        let dayString = ""

        monthString = month.toString().padStart(2, "0");
        dayString = day.toString().padStart(2, "0");

        return monthString + "." + dayString
    })

    return (
        // 350 x 80
        <div className="flex flex-col pr-2 lg:pr-0 lg:w-[350px] h-[80px] mb-3">
            <div className="flex items-center">
                <div className="text-lg border-solid font-bold border-white border-4 rounded-xl text-center w-[100px]">
                    {renderDate(month, day)}
                </div>
                <div className="text-transparent bg-white h-1 w-full">
                    ______
                </div>
            </div>
            <div className={`font-ubcani-heading2 text-end font-normal not-italic text-4xl pt-2 ${isBigEvent ? "text-ubcani-aqua" : ""}`}>
                {eventName.toUpperCase()}
            </div>
        </div>
    )
}

export default EventDate