import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Sponsors.css";

import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import SponsorIcon from "./SponsorIcon/SponsorIcon";
import SponsorPanel from "./SponsorPanel/SponsorPanel";

import hollowStar from "../../assets/typography/hollowStar.webp"
import solidStar from "../../assets/typography/solidStar.webp"
import sponsorsData from "../../data/sponsor.json"

function Sponsor() {
  const [sponsors, setSponsors] = useState([]);

  // sort the list of sponsor based on the type of sponsorship
  const sponsorSort = (a, b) => {
    if (a["type"] < b["type"]) {
      return -1;
    } else if (a["type"] > b["type"]) {
      return 1;
    } else {
      return 0;
    }
  }

  const foodSponsorFilter = (sponsor) => {
    return sponsor["type"] === "FOOD";
  }
  const merchSponsorFilter = (sponsor) => {
    return sponsor["type"] === "MERCH";
  }
  const miscSponsorFilter = (sponsor) => {
    return sponsor["type"] === "MISC";
  }

  useEffect(() => {
    sponsorsData.sort(sponsorSort);
    setSponsors(sponsorsData)
  }, [sponsors]);

  return (
    <div className="App">
      <header className="App-header">
        <Navbar />
      </header>
      <div id="sponsors" className="bg-background-blue">
        <div className="flex justify-center items-center p-4 lg:w-42 lg:p-12">
          <div className="flex flex-row items-center gap-x-2">
            <img loading="lazy" className="h-[15vw] lg:h-20" src={hollowStar} alt=""></img>
            <h1 className="text-[10vw] md:text-7xl pb-2">SPONSORS</h1>
            <img loading="lazy" className="h-[15vw] lg:h-20" src={solidStar} alt=""></img>
          </div>
        </div>
        <div className="px-4 lg:px-40">
          <p className="pb-10">
            Here is our list of sponsors for the year 2024-2025! These sponsors have kindly given
            members of the UBC Anime Club discounts at their stores through the school year.
            Present your club membership card at these locations before transaction to gain
            access to the discounts.
          </p>
          <p className="pb-10">
            If you would like to become one of our valued sponsors, please send us an email
            at ubcani@gmail.com!
          </p>
          <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 lg:gap-x-10 gap-y-5 lg:gap-y-10 pb-10 lg:pb-20">
            {sponsors.map((sponsor, index) => {
              return (
                <div key={index} className="flex justify-center items-center">
                  <SponsorIcon id={sponsor["id"]} iconLink={sponsor["icon"]} type={sponsor["type"]} />
                </div>
              )
            })}
          </div>
        </div>
        {/* FOOD SPONSORS */}
        <div className="px-4 xl:px-40 pb-20">
          <h2 className="text-ubcani-pink text-7xl pb-10">FOOD</h2>
          <div className="flex flex-col lg:grid grid-cols-2 gap-x-10 gap-y-10">
            {sponsors.filter(foodSponsorFilter).map((sponsor) => {
              return (
                <SponsorPanel key={sponsor["id"]} sponsor={sponsor} />
              )
            })}
          </div>
        </div>
        {/* MERCH SPONSORS */}
        <div className="px-4 xl:px-40 pb-20">
          <h2 className="text-ubcani-aqua text-7xl pb-10">MERCH</h2>
          <div className="flex flex-col lg:grid grid-cols-2 gap-x-10 gap-y-10">
            {sponsors.filter(merchSponsorFilter).map((sponsor) => {
              return (
                <SponsorPanel key={sponsor["id"]} sponsor={sponsor} />
              )
            })}
          </div>
        </div>
        {/* MISC SPONSORS */}
        <div className="px-4 xl:px-40 pb-20">
          <h2 className="text-ubcani-yellow text-7xl pb-10">MISC</h2>
          <div className="flex flex-col lg:grid grid-cols-2 gap-x-10 gap-y-10">
            {sponsors.filter(miscSponsorFilter).map((sponsor) => {
              return (
                <SponsorPanel key={sponsor["id"]} sponsor={sponsor} />
              )
            })}
            <div id="coming_soon" className="flex justify-end items-end w-full h-full bg-background-footer rounded-3xl">
              <div className="p-4">
                <h3 className="text-right text-background-blue text-6xl">MORE <br></br> COMING <br></br>SOON</h3>
              </div>
            </div>
          </div>
        </div>

      </div>

      <Footer />
    </div>
  );
}

export default Sponsor;
