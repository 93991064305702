
import hollowStar from "../../../../assets/typography/hollowStar.webp"
// import other event previews here
import beach2022 from "../../../../assets/events/gallery/2022/beach.webp"
import halloween2022 from "../../../../assets/events/gallery/2022/halloween.webp"
import hanami2022 from "../../../../assets/events/gallery/2022/hanami.webp"
import icebreaker2022 from "../../../../assets/events/gallery/2022/icebreaker.webp"
import valentines2022 from "../../../../assets/events/gallery/2022/valentines.webp"

import "../EventGallery.css"

/**
 * Template to build an event gallery component for a specific school year.
 * 
 * Copy this file into its own folder and rename the file to be EventGallery[Year], where Year represent the school year Year/Year+1
 * Example: EventGallery2019 is the event gallery for the school year 2019/2020
 *  
 * This file contains an example of an event gallery component, adjust and add new gallery elements as needed
 * 
 */

function EventGallery2022() {

    // contains the link to the corresponding photo albums
    const galleryLink = {
        "icebreaker2022": "https://photos.app.goo.gl/7xTLBuZKnfKpZ7FVA",
        "halloween2022": "https://photos.app.goo.gl/Qaa2sHwShixHZhwb7",
        "valentines2022": "https://photos.app.goo.gl/oivVecG4NKkz5ThX7",
        "beach2022": "https://photos.app.goo.gl/5bSNQYVcYzYdNZZn6",
        "hanami2022": "https://photos.app.goo.gl/pEb5KbwNd7wuDTLe7"
    }

    /**
     * Render the year heading for the current element
     * 
     * @param year the school year, as defined above 
     * @returns the rendered display of the school year
     */
    const schoolYearElement = (year) => {

        return (

            <div className="schoolYearElement">
                <img loading="lazy" src={hollowStar}></img>
                <h2 >
                    {year}/{year + 1}
                </h2>
            </div>
        )
    }

    /**
     * 
     * @param eventName name of the event
     * @param eventGalleryLink link to a gallery of event photos. Usually a Google Photo link
     * @param previewImage preview image, must be imported beforehand
     * @returns the rendered event gallery element
     */

    const eventGalleryElement = (eventName, eventGalleryLink, previewImage) => {

        return (
            <div className="eventGalleryElement">
                <div className="eventGalleryChild1">
                    <a href={eventGalleryLink}>
                        <img loading="lazy" src={previewImage} />
                    </a>
                </div>

                <div>
                    <h3 >
                        {eventName.toUpperCase()}
                    </h3>
                </div>
            </div>
        )
    }

    return (
        <div className="eventGalleryGrid" >

            {schoolYearElement(2022)}

            {eventGalleryElement("ICEBREAKER", galleryLink["icebreaker2022"], icebreaker2022)}
            {eventGalleryElement("HALLOWEEN SOCIAL", galleryLink["halloween2022"], halloween2022)}
            {eventGalleryElement("VALENTINES SOCIAL", galleryLink["valentines2022"], valentines2022)}
            {eventGalleryElement("BEACH EPISODE", galleryLink["beach2022"], beach2022)}
            {eventGalleryElement("POP-UP HANAMI", galleryLink["hanami2022"], hanami2022)}

        </div>
    )
}

// don't forget to edit this one as well
export default EventGallery2022