import React, { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import "./Footer.css";
import tano from "../assets/tano fg.png";
import shiani from "../assets/shiani fg.png";

const JOIN_LINK = `/join`;
const CONTACT_US_LINK = `/contactus`;
const DISCORD_LINK = `https://discord.gg/ubcani`;
const EMAIL_LINK = `mailto:ubcani@gmail.com`;
const INSTAGRAM_LINK = `https://www.instagram.com/ubcani`;
const TWITTER_LINK = `https://twitter.com/ubcani`;
const MAP_LINK = `https://maps.app.goo.gl/BozyamBAaE22zrxP9`;
const LINKTREE_LINK = `https://linktr.ee/ubcani`;

const MAILCHIMP_URL = `https://ubcani.us7.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`

const NewsletterForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault()

    email &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,

      });
  }


  return (
    <form onSubmit={handleSubmit} className="grow lg:w-[400px] px-4 lg:px-0">
      <div className="flex items-center border-b border-highlight-blue bg-text-white rounded-lg py-2">
        <input
          className="appearance-none bg-text-white border-none w-full text-text-dark mr-3 px-4 py-2 lg:py-1 lg:px-2 leading-tight focus:outline-none text-xl lg:text-base"
          type="email"
          placeholder="yubi.shiani@ubcani.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="pr-2">
          <button
            className="flex-shrink-0 bg-highlight-blue hover:bg-highlight-select-blue border-highlight-blue hover:border-highlight-select-blue text-xl lg:text-sm border-4 text-white py-1 px-2 rounded"
            type="submit"
          >
            Subscribe
          </button>
        </div>
      </div>

      {status === "success" && (
        <div className="text-background-blue">
          Success! You've been subscribed.
        </div>
      )}
      {status === "sending" && (
        <div className="text-background-blue">
          Subscribing...
        </div>
      )}
      {status === "error" && (
        <div className="text-background-blue">
          Oops! something went wrong.
        </div>
      )}

    </form>
  )
}

function Footer() {

  return (
    <div className="pb-60 scroll-pb-0 lg:pb-0 bg-background-footer w-full overflow-hidden">
      <div className="Footer-section flex flex-col md:flex-row items-stretch grow">
        <div className="hidden lg:flex flex-none tano max-w-full">
          <img loading="lazy" src={tano} alt="img1" className="self-end justify-content-left" />
        </div>

        <div className="w-full px-4 xl:ml-16 lg:w-2/5 pt-8 ">
          <h3 className="pb-4 text-background-blue text-[7vw] md:text-3xl text-center lg:text-start">CONNECT WITH US</h3>
          <div>
            <ul className="font-bold columns-2 lg:columns-1 pb-8 text-center lg:text-start text-background-blue">
              <li>
                <a href={JOIN_LINK}>
                  <p >Join the Club!
                  </p>
                </a>
              </li>
              <li>
                <a href={CONTACT_US_LINK}>
                  <p >Contact Us
                  </p>
                </a>
              </li>
              <li>
                <a href={DISCORD_LINK} target="_blank" rel="noreferrer">
                  <p>Discord
                  </p>
                </a>
              </li>
              <li>
                <a href={EMAIL_LINK}>
                  <p>Email
                  </p>
                </a>
              </li>
              <li>
                <a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer">
                  <p>Instagram
                  </p>
                </a>
              </li>
              {/* <li>
                <a href={TWITTER_LINK}>
                  <p>X (Twitter)
                  </p>
                </a>
              </li> */}
              {/* <li>
                <a href={TWITTER_LINK}>
                  <p>Address
                  </p>
                </a>
              </li> */}
              {/* <li>
              <p className="text-background-blue">Mailing
              </p>
            </li> */}
              <li>
                <a href={LINKTREE_LINK} target="_blank" rel="noreferrer">
                  <p>linktr.ee
                  </p>
                </a>
              </li>
            </ul>
          </div>
          {/* <div>
            Mailing Address:
            <br></br>Box 107
            <br></br>6138 Student Union Boulevard
            <br></br>Vancouver, BC V6T 1Z1
          </div> */}
        </div>

        <div className="flex flex-col h-full xl:mr-16 px-4 py-8 md:pb-0">
          <h3 className="pb-4 text-center md:text-left text-[7vw] md:text-3xl text-background-blue">NEWSLETTER</h3>
          <div className="flex md:block">
            {/* <input
          type="email"
          placeholder="Enter your email here"
          className="Newsletter-input"
        />
        <button type="submit">
          Subscribe
        </button> */}

            <MailchimpSubscribe
              url={MAILCHIMP_URL}
              render={({ subscribe, status, message }) => (
                <NewsletterForm
                  status={status}
                  message={message}
                  onValidated={formData => subscribe(formData)}
                />
              )}
            />
          </div>
          <p className="text-center md:text-left pt-5 px-4 lg:px-0">
            Subscribe for weekly newsletters about our upcoming events, news, and
            opportunities!
          </p>
          <br />
          <p className="items-end text-center md:text-left ">
            © 2025 UBC Anime Club. All rights reserved.
          </p>
        </div>

        <div className="hidden lg:flex overflow-x-hidden flex-none shiani max-w-full pb-20 lg:pb-0">
          <img loading="lazy" src={shiani} alt="img1" className="flex self-end" />
        </div>
      </div>
    </div>
  );
}
export default Footer;
