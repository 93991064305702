import globe from "../../../assets/sponsors/globe.png"
import phone from "../../../assets/sponsors/phone.png"
import instagram from "../../../assets/sponsors/instagram.png"
import facebook from "../../../assets/sponsors/facebook.png"

function SponsorPanel({ sponsor }) {

    const backgroundColor = {
        "FOOD": "bg-ubcani-pink",
        "MERCH": "bg-ubcani-aqua",
        "MISC": "bg-ubcani-yellow"
    }

    return (
        <div id={sponsor["id"]} className={`w-full h-full rounded-3xl items-center ${backgroundColor[sponsor["type"]]}`}>
            <div className="flex flex-col lg:flex-row lg:h-80 p-8 gap-x-5">
                <div className="lg:w-3/5 lg:h-full overflow-hidden pb-4 lg:pb-0">
                    <img loading="lazy" src={sponsor[sponsor["default"]]} className="object-cover w-full h-[200px] lg:h-full rounded-3xl" alt="sponsor"></img>
                </div>
                <div className="lg:w-3/5 flex flex-col justify-between">
                    <div>
                        <h2 className="text-4xl text-background-blue">
                            {sponsor["name"].toUpperCase()}
                        </h2>
                        <p className="text-sm text-background-blue pb-5 italic">
                            {sponsor["address"]}
                        </p>
                        <p className="text-sm font-bold text-background-blue pb-5">
                            {sponsor["description"]}
                        </p>
                    </div>
                    <div className="flex flex-col">
                        {sponsor["phone_number"] !== "" ?
                            <div className="flex items-center">
                                <img loading="lazy" className="" src={phone} alt="phone icon" />
                                <p className="text-sm font-bold text-background-blue">{sponsor["phone_number"]}</p>
                            </div> :
                            <></>
                        }
                        <div className="flex">
                            {sponsor["website_link"] !== "" ?
                                <a className="size-[40px] flex items-center justify-center" href={sponsor["website_link"]} >
                                    <img loading="lazy" src={globe} alt="website icon" />
                                </a>
                                :
                                <></>
                            }
                            {sponsor["instagram_link"] !== "" ?
                                <a className="size-[40px] flex items-center justify-center" href={sponsor["instagram_link"]}>
                                    <img loading="lazy" className="" src={instagram} alt="instagram icon" />
                                </a>
                                :
                                <></>
                            }
                            {sponsor["facebook_link"] !== "" ?
                                <a className="size-[40px] flex items-center justify-center" href={sponsor["facebook_link"]}>
                                    <img loading="lazy" className="" src={facebook} alt="facebook icon" />
                                </a>
                                :
                                <></>
                            }
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default SponsorPanel