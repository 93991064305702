import React, { } from "react";
import "./ContactUs.css";

import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import hollowStar from "../../assets/typography/hollowStar.webp"
import solidStar from "../../assets/typography/solidStar.webp"

const FEEDBACK_FORM = `https://forms.gle/b4QzYuKyqEJkEdqP7`
const REPORT_FORM = `https://forms.gle/4gLu52QjXz6BtLGo9`

function ContactUs() {

  return (
    <div className="App">
      <header className="App-header">
        <Navbar />
      </header>
      <div className="md:px-8 lg:pb-20">
        <div className="flex justify-center items-center p-4 lg:w-42 lg:p-12">
          <div className="flex flex-col">
            <div>
              <h1 className="text-[11.5vw] md:text-7xl pb-2">CONTACT US
              </h1>
            </div>
            <div className="bg-ubcani-pink text-transparent h-2 md:h-3">
              ______
            </div>
          </div>
        </div>
        <div className="px-4 lg:px-40">
          <p className="pb-10 text-pretty">
            If you would like to reach out to us for club-related inquiries, please send us an email
            to ubcani@gmail.com.
          </p>
          <p id="feedback" className="pb-10 text-pretty">
            Have ideas or suggestions for the club or the Discord server? Want to provide feedback for an event?
            Let us know how we can improve by submitting us a feedback 
            through our <a href={FEEDBACK_FORM} target="_blank" rel="noreferrer" className="text-ubcani-light-blue">feedback form</a>!
          </p>
          <p id="report" className="pb-10 text-pretty">
            We take the safety of our club members seriously. If at any point you feel uncomfortable or distressed,
            please don't hesitate to reach out to a moderator or executive member for support. If you prefer,
            you can fill out the 
            anonymous <a href={REPORT_FORM} target="_blank" rel="noreferrer" className="text-ubcani-light-blue">report form</a> to let us know your concerns.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ContactUs;
