import React, {  } from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Home.css";

import Navbar from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";

import ellipse from "../../assets/mascot_cosplayers.webp";
import splash from "../../assets/splash_page.webp";
import hanami from "../../assets/hanami.webp";
import hanamiLeft from "../../assets/hanamiLeft.webp";
import hanamiRight from "../../assets/hanamiRight.webp";
import membership from "../../assets/membership.webp";

const images = [
  hanami, hanamiLeft, hanamiRight
];

function Home() {

  return (
    <div className="App">
      <header className="App-header">
        <Navbar />
      </header>
      <img loading="" src={splash} className="App-logo h-full w-full object-cover object-center" alt="logo" />
      <div className="flex justify-center items-center w-42 bg-background-blue p-4 lg:p-12 xl:p-24">
        <div className="flex flex-col lg:grid grid-cols-3 bg-background-blue justify-left lg:gap-y-20 lg:gap-x-20 xl:gap-y-40 xl:gap-x-30">
          <img src={ellipse} className="rounded-full p-8 md:p-4 lg:p-0 self-center" alt="shiani and tano cosplayer" />
          <div className="col-span-2">
            <div className="WhoAreWe">
              <h1 >WHO ARE WE?</h1>
              <p className="p-2 pb-6 ">
                The UBC Anime Club is a student-run organization located in Vancouver, BC, Canada that
                brings together individuals who share a passion for anime,
                manga, and Japanese culture.
              </p>
            </div>
            <div className="WeeklyEvents">
              <h1 >WEEKLY EVENTS EVERY FRIDAY</h1>
              <p className="p-2 pb-6">
                We host drop-in activities every Friday from 5pm to 10pm,
                which include karaoke, anime-themed trivia, video games,
                workshops, and much more! These weeklies are drop-in
                and free to attend.
              </p>
            </div>
            <div className="Community">
              <h1>GROWING COMMUNITY</h1>
              <p className="p-2 pb-6">
                We have over 500 members from all across the lower
                mainland involved in various interests such as anime, art,
                gaming, and more!
              </p>
            </div>
          </div>
        </div>
      </div>


      <div className="hanami">
        <div className="lg:hidden">
          <div>
            <h1 className="hanamiText text-[10vw] pl-4 lg:pl-0">POP-UP HANAMI</h1>
            <div className="bg-ubcani-pink text-transparent h-2 md:h-3">
              ______
            </div>
          </div>
        </div>

        <Carousel className="slider" autoPlay="true" interval="3000" infiniteLoop="true" transitionTime="1000" stopOnHover="true">
          {images.map((item, index) => (
            <div className="slide object-contain object-[60%_50%] w-full" key={index}>
              <img loading="lazy" alt="sample_file" src={item} />
            </div>
          ))}
        </Carousel>
        <div className="hidden lg:absolute lg:inset-40 lg:flex lg:justify-end lg:items-end">
          <div>
            <h1 className="hanamiText text-[150px]">POP-UP HANAMI</h1>
            <div className="bg-ubcani-pink text-transparent h-2 md:h-3">
              ______
            </div>
          </div>
        </div>
      </div>
      <div className="membership pb-10">
        <h1 className="lg:pb-20 pb-5 text-[10vw] md:text-7xl"> MEMBERSHIP BENEFITS</h1>
        <div className="flex justify-center items-center px-4 lg:px-16 xl:pl-40 xl:pr-40">
          <div className="flex flex-col lg:flex-row justify-center items-center gap-x-8">
            <div className="flex flex-col justify-evenly gap-y-10 lg:pr-20">
              <div>
                <div className="rounded-3xl bg-highlight-blue p-5">
                  <p className="text-text-dark text-xl">
                    Purchasing a unique membership card helps
                    support the club so we can continue running
                    events. However, you do not have to be a member
                    to participate in any club activities!
                  </p>
                </div>

              </div>
              <div >
                <div className="rounded-3xl bg-ubcani-yellow p-5">
                  <p className="text-text-dark text-xl">
                    Enjoy exclusive discounts when you present your
                    membership card at our <b>sponsor locations</b>, which include Catoro Pets, Ki Cafe, Hobby Bee and
                    many more!
                  </p>
                </div>
              </div>
              <div>
                <div className="rounded-3xl bg-ubcani-pink p-5">
                  <p className="text-text-dark text-xl">
                    Receive <b>discounted admission</b> to our larger events,
                    such as Halloween Social and Pop-up Hanami, as
                    well as additional raffles to increase your chances
                    of winning a prize!
                  </p>
                </div>
              </div>

            </div>
            <img loading="lazy" className="flex my-4 border-4 border-none rounded-3xl" src={membership} alt="Membership Cards" />
          </div>
        </div>


      </div>
      <Footer />
    </div>
  );
}

export default Home;
