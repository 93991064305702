import React from "react";
import "./ArtistCollective.css";
import artist from "../../assets/icon.jpg";
import solidStar from "../../assets/typography/solidStar.webp";

const ArtistCollective = () => {
  return (
    <div className="lg:pb-40 lg:pr-32">
      <div className="flex lg:w-42 items-center pl-4 lg:p-8 lg:pl-20">
        <div className="flex flex-col">
          <div>
            <h1 className="text-[8vw] md:text-7xl pb-2 text-text-white">
              ARTIST COLLECTIVE
            </h1>
          </div>
          <div className="bg-ubcani-yellow text-transparent h-2 md:h-3">
            ______
          </div>
        </div>
        <img loading="lazy" className="h-2/5 lg:h-auto" src={solidStar} alt="Hanami Star"></img>
      </div>

      <div className="p-4 lg:pr-40 lg:pl-20 pb-4 lg:pb-10">
        <p className="text-text-white">
          The UBCAni <strong>Artist Collective</strong> is the heart of the club's creative
          endeavors! From contributing to graphics for all the club's events,
          to creating assets for activities, to helping fellow artists learn
          and grow, the Artist Collective provides a space for beginner and
          experienced artists to work on fun projects as part of a team.
          <br />
          <br />
          We'll be recruiting for Artist Collective several times throughout
          the year! Look out for new projects like:
        </p>
        <ul>
          <li><p>Video Game Design</p></li>
          <li><p>Zines</p></li>
          <li><p>Manga Writing</p></li>
          <li><p>... stay tuned for more!</p></li>
        </ul>

      </div>
      {/* <div className="pb-4 lg:pb-10">
        <div className="artist-grid">
          <div className="artist-icon">
            <img loading="lazy" src={artist} alt="Artist 1" />
            <p>name</p>
          </div>
          <div className="artist-icon">
            <img loading="lazy" src={artist} alt="Artist 2" />
            <p>name</p>
          </div>
          <div className="artist-icon">
            <img loading="lazy" src={artist} alt="Artist 3" />
            <p>name</p>
          </div>
          <div className="artist-icon">
            <img loading="lazy" src={artist} alt="Artist 4" />
            <p>name</p>
          </div>
          <div className="artist-icon">
            <img loading="lazy" src={artist} alt="Artist 5" />
            <p>name</p>
          </div>
          <div className="artist-icon">
            <img loading="lazy" src={artist} alt="Artist 6" />
            <p>name</p>
          </div>
        </div>
      </div> */}

    </div>
  );
};

export default ArtistCollective;
