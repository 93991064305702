
import hollowStar from "../../../../assets/typography/hollowStar.webp"
// import other event previews here
import icebreaker2021 from "../../../../assets/events/gallery/2021/icebreaker.webp"
import isekaiya2021 from "../../../../assets/events/gallery/2021/isekaiya.webp"
import valentines2021 from "../../../../assets/events/gallery/2021/valentines.webp"
import halloween2021 from "../../../../assets/events/gallery/2021/halloween.webp"

import "../EventGallery.css"

/**
 * Template to build an event gallery component for a specific school year.
 * 
 * Copy this file into its own folder and rename the file to be EventGallery[Year], where Year represent the school year Year/Year+1
 * Example: EventGallery2019 is the event gallery for the school year 2019/2020
 *  
 * This file contains an example of an event gallery component, adjust and add new gallery elements as needed
 * 
 */

function EventGallery2021() {

    // contains the link to the corresponding photo albums
    const galleryLink = {
        "icebreaker2021": "https://photos.app.goo.gl/crSoTfFFiwYVy9Hc7",
        "halloween2021": "https://photos.app.goo.gl/GQWTPZEpXEnwZ6MSA",
        "valentines2021": "https://photos.app.goo.gl/qPZ1WRpMuXZx2ksj8",
        "isekaiya2021": "https://photos.app.goo.gl/XvAYbBmB5z6dPbc4A"
    }

    /**
     * Render the year heading for the current element
     * 
     * @param year the school year, as defined above 
     * @returns the rendered display of the school year
     */
    const schoolYearElement = (year) => {

        return (

            <div className="schoolYearElement">
                <img loading="lazy" src={hollowStar}></img>
                <h2 >
                    {year}/{year + 1}
                </h2>
            </div>
        )
    }

    /**
     * 
     * @param eventName name of the event
     * @param eventGalleryLink link to a gallery of event photos. Usually a Google Photo link
     * @param previewImage preview image, must be imported beforehand
     * @returns the rendered event gallery element
     */

    const eventGalleryElement = (eventName, eventGalleryLink, previewImage) => {

        return (
            <div className="eventGalleryElement">
                <div className="eventGalleryChild1">
                    <a href={eventGalleryLink}>
                        <img loading="lazy" src={previewImage} />
                    </a>
                </div>

                <div>
                    <h3 >
                        {eventName.toUpperCase()}
                    </h3>
                </div>
            </div>
        )
    }

    return (
        <div className="eventGalleryGrid" >

            {schoolYearElement(2021)}

            {eventGalleryElement("ICEBREAKER", galleryLink["icebreaker2021"], icebreaker2021)}
            {eventGalleryElement("HALLOWEEN SOCIAL", galleryLink["halloween2021"], halloween2021)}
            {eventGalleryElement("VALENTINES SOCIAL", galleryLink["valentines2021"], valentines2021)}
            {eventGalleryElement("COSCAFE: THE ISEKAIYA", galleryLink["isekaiya2021"], isekaiya2021)}

        </div>
    )

}

// don't forget to edit this one as well
export default EventGallery2021

