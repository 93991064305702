import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import HomePage from "./pages/Home/Home";
import About from "./pages/About/About";
import MascotGallery from "./pages/About/MascotGallery/MascotGallery";
import Events from "./pages/Events/Events";
import Membership from "./pages/Membership/Membership";
import Sponsors from "./pages/Sponsors/Sponsors";
import Join from "./pages/Join/Join";
import Maintenance from "./pages/Maintenance";
import ContactUs from "./pages/ContactUs/ContactUs";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <div>404 Not Found</div>,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/about/mascotGallery",
    element: <MascotGallery />,
  },
  {
    path: "/events",
    element: <Events />,
  },
  {
    path: "/membership",
    element: <Membership />,
  },
  {
    path: "/sponsors",
    element: <Sponsors />,
  },
  {
    path: "/join",
    element: <Join />,
  },
  {
    path: "/contactus",
    element: <ContactUs />
  }
]);

function App() {

  // env variables are treated as string
  if (process.env.REACT_APP_IS_MAINTENANCE === "true") {
    return (
      <Maintenance/>
    )
  } else {
    return (
      <RouterProvider router={router} />
    )
  }

}

export default App;
